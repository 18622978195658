//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

import { faCoinVertical } from '@fortawesome/pro-solid-svg-icons';

import authMixin from '@motionelements/core/src/components/mixins/auth.mixin.js';

export default {
  name: 'TheNavBar',
  mixins: [authMixin],
  components: {
    NuxtClientOnly: () => import('@motionelements/core/src/components/nav/NuxtClientOnly.vue'),
    NavLogo: () => import('@motionelements/core/src/components/nav/NavLogo.vue'),
    MenuLanguageNavDropdown: () => import('@motionelements/core/src/components/nav/MenuLanguageNavDropdown.vue'),
    UserNavDropdown: () => import('@/components/core/nav/UserNavDropdown.vue'),
    StudioAiDropdown: () => import('@/components/core/nav/StudioAiDropdown.vue'),
  },
  data() {
    return {
      faCoinVertical,
    };
  },
  computed: {
    ...mapState({
      member: state => state.user.member,
      isLoggedIn: state => state.user.isLoggedIn,
      currentRoute() {
        return window.location.href;
      },
    }),
  },
};
